import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSackDollar } from '@fortawesome/pro-solid-svg-icons/faSackDollar';
import { faTableTennisPaddleBall } from '@fortawesome/pro-solid-svg-icons/faTableTennisPaddleBall';
import { faMasksTheater } from '@fortawesome/pro-solid-svg-icons/faMasksTheater';
import { faHandsClapping } from '@fortawesome/pro-solid-svg-icons/faHandsClapping';
import { faCalendarStar } from '@fortawesome/pro-solid-svg-icons/faCalendarStar';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import { clearActiveListItem } from 'components/map/helpers';
import Container, { Filters } from 'components/map/Container';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import {
	debounce,
	getUrlByType,
	isExternalUrl,
	outputPlainText,
} from 'libs/content';
import { ConditionalLink } from 'components/Link';
import InputField from 'components/forms/InputField';
import MultiSelectField from 'components/forms/MultiSelectField';
import List from 'components/map/List';
import Loading from 'components/Loading';
import categoryIdrett from 'images/sponsing/idrett.svg';
import categoryKultur from 'images/sponsing/kultur.svg';
import categoryLagetMitt from 'images/sponsing/lagetmitt.svg';
import categoryArrangement from 'images/sponsing/arrangement.svg';
import categoryAnnet from 'images/sponsing/annet.svg';

const Map = lazy(() => import('components/map/Map.js'));

//#region Styling
const MapWrapper = styled(Map)`
	position: relative;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	${p =>
		p.theme.media.large(css`
			height: 100% !important;
		`)}
`;

const SearchClear = styled.button`
	background-color: transparent;
	border: 0;
	margin-left: auto;
	svg {
		width: 17px;
		height: 26px;
		color: ${p => p.theme.colors.grey700};
	}
	&:hover svg {
		color: ${p => p.theme.colors.blue600};
	}
`;

//#endregion

export default function SponsorshipsMap({ title, ...props }) {
	const sponsorshipsData = useStaticQuery(sponsorshipQuery);
	const sponsorships = sponsorshipsData?.sponsorships?.nodes || [];

	const [searching, setSearching] = useState(false);
	const [results, setResults] = useState(sponsorships);
	const [selectedYears, setSelectedYears] = useState([]);
	const [selectedPlaces, setSelectedPlaces] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const mapRef = useRef(null);
	const searchInputRef = useRef(null);

	library.add(
		faSackDollar,
		faTableTennisPaddleBall,
		faMasksTheater,
		faHandsClapping,
		faCalendarStar
	);
	dom.watch();

	/**
	 * Clear the search field
	 * @returns {void}
	 **/
	function handleClear() {
		setSearchTerm('');
		searchInputRef.current.value = '';
		setSelectedYears([]);
		setSelectedPlaces([]);
		setSelectedCategories([]);
		setSearching(false);
		setResults(sponsorships);
		clearActiveListItem();
	}

	/**
	 * Function to handle search, debounced to avoid multiple calls
	 * @param {string} value - The value to search for
	 * @returns {void}
	 **/
	const handleSearch = debounce(e => {
		if (!e?.target?.value?.length > 2) return;
		setSearching(true);
		setSearchTerm(e?.target?.value);
	}, 500);

	/**
	 * Function to add or remove an item from an array
	 * @param {Array} array - The array to add or remove the item from
	 * @param {any} item - The item to add or remove
	 * @returns {Array} - The updated array
	 */
	function toggleArrayItem(array, item) {
		const index = array.indexOf(item);
		if (index > -1) {
			return array.filter(i => i !== item);
		} else {
			return [...array, item];
		}
	}

	useEffect(() => {
		if (!sponsorships?.length || !searching) {
			return;
		}

		// Filter the sponsorships based on the search term
		const matchesSearchTerm = sponsorship => {
			if (!searchTerm) return true;

			return [
				sponsorship?.title,
				sponsorship?.text?.raw,
				sponsorship?.category,
				sponsorship?.place,
			].some(text =>
				text?.toLowerCase()?.includes(searchTerm?.toLowerCase())
			);
		};

		// Filter the sponsorships based on the selected years
		const matchesYear = sponsorship => {
			if (selectedYears.length === 0) return true;
			return selectedYears.includes(sponsorship?.year?.toString());
		};

		// Filter the sponsorships based on the selected categories
		const matchesCategory = sponsorship => {
			if (selectedCategories.length === 0) return true;
			return selectedCategories.includes(sponsorship?.category);
		};

		// Filter the sponsorships based on the selected places
		const matchesPlace = sponsorship => {
			if (selectedPlaces.length === 0) return true;

			const placesInCounty = getPlacesByCounty(selectedPlaces);
			if (Array.isArray(placesInCounty) && placesInCounty.length > 0) {
				return placesInCounty?.includes(sponsorship?.place);
			}

			return selectedPlaces.includes(sponsorship?.place);
		};

		// Filter the sponsorships based on the search term, year, place and category
		const res = sponsorships.filter(
			s =>
				matchesSearchTerm(s) &&
				matchesYear(s) &&
				matchesCategory(s) &&
				matchesPlace(s)
		);

		setResults(res);
		setSearching(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searching, sponsorships?.length]);

	return (
		<div className="sponsorship-map" {...props}>
			<Container
				map={
					<Suspense fallback={<Loading text="Laster kart ..." />}>
						<MapWrapper
							ref={mapRef}
							source="sponsorships"
							icon="fa-regular fa-gift"
							iconBg={true}
							markers={results?.map(entry => {
								return {
									...entry,
									link: {
										slug:
											entry?.button?.href ||
											entry?.button?.linkref?.slug,
										internal: {
											type: entry?.button?.linkref
												?.__typename,
										},
									},
									internalLink: !isExternalUrl(
										entry?.button?.href ||
											entry?.button?.linkref?.slug
									),
									linkText: entry?.button?.text,
								};
							})}
							popupLinkClick={props => {
								if (!props?.link) return;
								if (isExternalUrl(props?.link)) {
									window.open(props?.link, '_blank');
								} else {
									navigate(props?.link);
								}
							}}
						/>
					</Suspense>
				}
				rightContent={
					<div className="list sponsorship-list">
						{title && <h2>{title}</h2>}
						<Filters
							perRow={4}
							items={[
								<InputField
									id="sponsorship-search"
									name="sponsorship-search"
									label="Søk etter tildeling"
									data-cy="sponsorships-filter__search"
									type="search"
									ref={searchInputRef}
									onChange={e => handleSearch(e)}
									leadingIcon={true}
									loading={searching}
									clear={
										(!searching && searchTerm && (
											<SearchClear
												onClick={handleClear}
												title="Klikk for å tømme søket">
												<FontAwesomeIcon
													icon={faCircleXmark}
												/>
											</SearchClear>
										)) ||
										null
									}
									icon={faSearch}
									{...props}
								/>,
								<MultiSelectField
									id="sponsorship-place"
									name="sponsorship-place"
									options={[
										...new Set(
											sponsorships
												?.map(s => s?.place)
												.filter(place => place)
										),
									]}
									data-cy="sponsorships-filter__place"
									loading={searching}
									placeholder="Velg område"
									label="Velg område"
									selectedOptions={selectedPlaces}
									onChange={(_, val) => {
										if (!val || !val?.length > 0) return;
										setSearching(true);

										const updatedPlaces = toggleArrayItem(
											selectedPlaces,
											val
										);
										setSelectedPlaces(updatedPlaces);
									}}
								/>,
								<MultiSelectField
									id="sponsorship-year"
									name="sponsorship-year"
									options={[
										...new Set(
											sponsorships
												?.map(s => s?.year?.toString())
												.filter(year => year)
										),
									]}
									data-cy="sponsorships-filter__year"
									loading={searching}
									placeholder="Velg år"
									label="Velg år"
									selectedOptions={selectedYears}
									onChange={(_, val) => {
										if (!val || !val?.length > 0) return;
										setSearching(true);

										const updatedYears = toggleArrayItem(
											selectedYears,
											val
										);
										setSelectedYears(updatedYears);
									}}
								/>,
								<MultiSelectField
									id="sponsorship-category"
									name="sponsorship-category"
									options={[
										'Kultur',
										'Idrett',
										'Arrangement',
										'Laget Mitt',
										'Annet',
									]}
									data-cy="sponsorships-filter__category"
									loading={searching}
									placeholder="Velg kategori"
									label="Velg kategori"
									selectedOptions={selectedCategories}
									onChange={(_, val) => {
										if (!val || !val?.length > 0) return;
										setSearching(true);

										const updatedCategories =
											toggleArrayItem(
												selectedCategories,
												val
											);
										setSelectedCategories(
											updatedCategories
										);
									}}
								/>,
							]}
						/>
						{(results?.length > 0 && (
							<List
								items={results?.map(entry => (
									<Sponsorat {...entry} />
								))}
							/>
						)) || <p>Ingen resultater på din filtering ...</p>}
					</div>
				}
			/>
		</div>
	);
}

const InnerWrap = styled.div`
	${p =>
		p.theme.media.smallUp(css`
			display: flex;
			gap: ${p => p.theme.spacing.desktop.small};
			flex-direction: row-reverse;
		`)}
`;

const Info = styled.div`
	flex-grow: 1;
	h3 {
		margin-bottom: 0;
	}
	> :last-child {
		margin-bottom: 0;
	}
`;

const Image = styled.div`
	flex: 0 0 192px;
	margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
	${p =>
		p.theme.media.smallUp(css`
			margin-bottom: 0;
		`)}
	img {
		width: 100%;
		height: auto;
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const Specs = styled.div`
	color: ${p => p.theme.colors.grey900};
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin: 5px 0 10px;
`;

function getCategoryImage(category) {
	const images = {
		Kultur: categoryKultur,
		Idrett: categoryIdrett,
		Arrangement: categoryArrangement,
		'Laget Mitt': categoryLagetMitt,
		Annet: categoryAnnet,
	};

	return images[category] || categoryAnnet;
}

/**
 * Sponsorat component to display a single sponsorship
 * @param {Object} props - The props for the component
 * @param {string} props.title - The title of the sponsorship
 * @param {string} props.category - The category of the sponsorship
 * @param {string} props.year - The year of the sponsorship
 * @param {string} props.text - The text of the sponsorship
 * @param {Object} props.link - The link to the sponsorship
 * @param {Array} props.images - The images for the sponsorship
 * @returns {JSX.Element} - The Sponsorat component
 */
function Sponsorat({ ...props }) {
	// Get the link for the button (if any)
	const link =
		props?.button?.linkref?.slug && props?.button?.linkref?.__typename
			? getUrlByType(
					props.button.linkref.slug,
					props.button.linkref.__typename
			  )
			: props?.button?.href;

	return (
		<InnerWrap>
			<Image>
				{(props?.images?.[0] && (
					<LazyImage ratio="16:10" {...props?.images?.[0]} />
				)) || (
					<img
						src={getCategoryImage(props?.category)}
						alt={props?.title}
					/>
				)}
			</Image>
			<Info>
				<Heading level="h3" className="h4">
					{link ? (
						<ConditionalLink
							to={link}
							title={props?.button?.text}
							clickable="true">
							{props?.title}
						</ConditionalLink>
					) : (
						props?.title
					)}
				</Heading>
				{(props?.year || props?.place || props?.category) && (
					<Specs>
						{[
							props?.category && props?.category,
							props?.place && props?.place,
							props?.year && props?.year,
						]
							.filter(Boolean)
							.join(', ')}
					</Specs>
				)}
				{props?.text && <p>{outputPlainText(props?.text)}</p>}
			</Info>
		</InnerWrap>
	);
}

/**
 * GraphQL query to get sponsorships from Contentful
 * @returns {Object} - The sponsorships data
 */
const sponsorshipQuery = graphql`
	query sponsorship {
		sponsorships: allContentfulInnholdReferanse(
			filter: { type: { eq: "Sponsorat" } }
			sort: { year: DESC }
		) {
			nodes {
				id
				title: tittel
				category
				year
				place
				location {
					lat
					lon
				}
				images: bilder {
					file {
						url
					}
					width
					height
					description
				}
				text: tekst {
					raw
				}
				button {
					__typename
					... on Node {
						... on ContentfulKomponentKnapp {
							...KnappQuery
						}
					}
				}
			}
		}
	}
`;

const countiesMap = {
	'Midtre-Trøndelag': [
		'Osen',
		'Ørland',
		'Indre Fosen',
		'Åfjord',
		'Inderøy',
		'Steinkjer',
		'Verdal',
		'Levanger',
		'Frosta',
		'Stjørdal',
		'Meråker',
		'Snåsa',
	],
	'Søndre-Trøndelag': [
		'Frøya',
		'Rindal',
		'Heim',
		'Rennebu',
		'Midtre Gauldal',
		'Selbu',
		'Holtålen',
		'Røros',
		'Tydal',
		'Oppdal',
		'Orkland',
		'Skaun',
		'Melhus',
		'Hitra',
	],
	Namdalen: [
		'Nærøysund',
		'Leka',
		'Flatanger',
		'Namsos',
		'Overhalla',
		'Grong',
		'Lierne',
		'Høylandet',
		'Namsskogan',
		'Røyrvik',
	],
	Trondheimsregionen: ['Trondheim', 'Malvik'],
};

function getPlacesByCounty(county) {
	if (!county || !countiesMap[county]) return null;

	return countiesMap[county];
}
