import React from 'react';
import styled, { css } from 'styled-components';

import MaxWidth from 'layouts/max-width';
import Heading from 'libs/heading';

const Steps = styled.ol`
	margin: 0;
	padding: 0;
	${p =>
		p.theme.media.medium(css`
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
		`)}
`;

const Step = styled.li`
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 20px;
	flex: 1;
	${p =>
		p.theme.media.mediumOnly(css`
			padding: 0 0 50px;
			flex: 1;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0 0 30px;
		`)}
`;

const Inner = styled.div`
	width: 100%;
	text-align: center;
`;

const Icon = styled.div`
	display: block;
	margin-bottom: 20px;
	${p =>
		p.theme.media.medium(css`
			margin-bottom: 30px;
		`)}
	svg {
		height: 245px !important;
	}
`;

const Text = styled.p`
	margin: 0;

	${p =>
		p.theme.media.large(css`
			padding: 0 45px;
		`)}
`;

/**
 * Represents an illustration of the steps in the process of sponsorship
 * @param {string} title - The title of the component.
 * @param {boolean} showFirstStep - Whether to show the first step or not
 * @param {array} steps - The steps in the process
 * @returns {JSX.Element} The rendered component
 */
export default function Sponsorship({
	title = '',
	showFirstStep = true,
	steps = [
		{
			icon: (
				<svg
					height="244.996"
					viewBox="0 0 205 244.996"
					width="205"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="102.5"
						cy="102.5"
						fill="#6dbc87"
						opacity=".2"
						r="102.5"
					/>
					<g fill="#ffd4bd">
						<path
							d="m476.461 374.476a5.678 5.678 0 0 0 -6.056 6.255l-18.269 8.569 8.942 5.475 15.977-8.972a5.708 5.708 0 0 0 -.594-11.322z"
							transform="translate(-323.211 -300.377)"
						/>
						<path
							d="m476.461 374.484c-1.676-.189-3.319.789-4.488 2.67a12.179 12.179 0 0 0 -1.568 7.076l-18.269 13.346 8.942 8.532 15.977-13.982c2.851-.765 4.883-4.732 4.732-9.233s-2.44-8.114-5.326-8.41z"
							transform="matrix(.70710678 -.70710678 .70710678 .70710678 -525.202 106.631)"
						/>
						<path
							d="m171.048 487.173h6.309l3-24.336h-9.312z"
							transform="translate(-93.395 -248.293)"
						/>
					</g>
					<path
						d="m347.981 694.99h12.426a7.919 7.919 0 0 1 7.918 7.918v.258h-20.344z"
						fill="#2f2e41"
						transform="translate(-271.937 -458.17)"
					/>
					<path
						d="m234.458 487.173h6.309l3-24.336h-9.312z"
						fill="#ffd4bd"
						transform="translate(-124.611 -248.293)"
					/>
					<path
						d="m411.391 694.99h12.426a7.919 7.919 0 0 1 7.918 7.918v.258h-20.344z"
						fill="#2f2e41"
						transform="translate(-303.153 -458.17)"
					/>
					<path
						d="m202.917 262.159 3.2 6.894 5.773 61.655-4.49 52.992h-10.5l-12.385-83.531-5.817 81.606-14.162-.572-2.719-110.64z"
						fill="#2f2e41"
						transform="translate(-88.855 -149.501)"
					/>
					<path
						d="m405.923 342.948 5.892-1.641s4.456-2.9 5.922 5.716 9.01 22.01 9.01 22.01l12.915-6.883 5.979 13.058-20.4 12.354-21.478-21.641z"
						fill="#d1d1d1"
						transform="translate(-299.4 -283.825)"
					/>
					<path
						d="m318.723 333.135 13.276-5.4 18.739 1.051 16.659 6.071 5.558 62.07c-14.493 7.914-29.174 10.074-44.111 4.415l-13.862-40.428z"
						fill="#d1d1d1"
						transform="translate(-255.692 -277.373)"
					/>
					<path
						d="m343.246 286.7a13.51 13.51 0 0 1 24.725-10.9l.077.179a13.512 13.512 0 1 1 -24.807 10.72z"
						fill="#ffd4bd"
						transform="translate(-269.039 -247.837)"
					/>
					<path
						d="m356.421 268.422c2.809.51 7.156 2.278 9.569.2a11.825 11.825 0 0 0 -4.378-3.2 2.7 2.7 0 0 1 -.906-.512c-.823-.831-.187-2.264-.563-3.387a2.9 2.9 0 0 0 -2-1.648c-.837-.251-1.715-.291-2.537-.6-1.947-.732-3.331-2.825-5.326-3.394-1.739-.5-3.488.279-4.988 1.191s-2.985 1.994-4.74 2.167c-1.312.12-2.142 2.277-3.459 2.34-2.76.134-2.606-1.685-2.81 1.214-.118 1.73-2.59 5.171-2.549 6.916.041 1.143-.161 2.276-.129 3.423a8.892 8.892 0 0 0 2.461 5.844c1.256 1.292 2.89 2.163 3.948 3.636a23.639 23.639 0 0 0 1.572 2.4 1.99 1.99 0 0 0 2.439.628c.715-.505.728-1.6 1.082-2.43a2.657 2.657 0 0 1 2.641-1.607 3.112 3.112 0 0 1 2.539 2.073c-.335-1.838-1.217-3.56-1.552-5.4s.1-4.037 1.685-4.755c.68-.316 1.6-.4 1.872-1.131a6.58 6.58 0 0 0 .079-1.433c.347-2.319 4.218-2.895 6.05-2.535z"
						fill="#2f2e41"
						transform="translate(-263.873 -241.926)"
					/>
					<path
						d="m322.978 451.389a5.678 5.678 0 0 0 -7.419-4.556l-12.321-15.977-3.406 9.916 12.223 13.652a5.708 5.708 0 0 0 10.924-3.035z"
						fill="#ffd4bd"
						transform="translate(-248.233 -328.139)"
					/>
					<path
						d="m298.482 341.378-4.256-3.014s-9.736-.558-11.153 7.436l-17.853 29.369 22.135 30.538 10.732-11.3-13.541-15.824 11.369-20.875z"
						fill="#d1d1d1"
						transform="translate(-231.195 -282.603)"
					/>
					<path
						d="m515.31 344.111h-.223v-6.116a3.54 3.54 0 0 0 -3.54-3.54h-12.959a3.54 3.54 0 0 0 -3.54 3.54v33.555a3.54 3.54 0 0 0 3.54 3.54h12.958a3.54 3.54 0 0 0 3.54-3.54v-23.086h.223z"
						fill="#3f3d56"
						transform="translate(-344.336 -280.682)"
					/>
					<path
						d="m512.636 336.268h-1.691a1.256 1.256 0 0 1 -1.163 1.73h-7.424a1.256 1.256 0 0 1 -1.163-1.73h-1.58a2.644 2.644 0 0 0 -2.644 2.644v33.506a2.644 2.644 0 0 0 2.644 2.644h13.021a2.644 2.644 0 0 0 2.644-2.644v-33.506a2.644 2.644 0 0 0 -2.644-2.644z"
						fill="#b5e4fc"
						transform="translate(-345.283 -281.575)"
					/>
					<text fill="#28292c" transform="translate(157.2 75)">
						<tspan x="0" y="0" />
					</text>
				</svg>
			),
			title: 'Steg 1',
			text: 'Søk etter midler på vegne av ditt lag eller din forening.',
		},
		{
			icon: (
				<svg
					height="236.076"
					viewBox="0 0 205 236.076"
					width="205"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="102.5"
						cy="102.5"
						fill="#6dbc87"
						opacity=".2"
						r="102.5"
					/>
					<path
						d="m64.091 33h70.093v42.025h-70.093z"
						fill="#b5e4fc"
					/>
					<path
						d="m881.432 405.021h-41.705a1.964 1.964 0 0 1 0-3.929h41.705a1.964 1.964 0 0 1 0 3.929z"
						fill="#28292c"
						opacity=".354"
						transform="translate(-761.233 -310.689)"
					/>
					<path
						d="m881.086 391.918h-28.256a1.964 1.964 0 0 1 0-3.929h28.257a1.964 1.964 0 0 1 0 3.929z"
						fill="#0089c4"
						opacity=".522"
						transform="translate(-767.611 -304.311)"
					/>
					<g fill="#28292c">
						<path
							d="m64.091 109.42h70.093v42.025h-70.093z"
							opacity=".2"
						/>
						<path
							d="m881.432 553.929h-41.705a1.964 1.964 0 0 1 0-3.929h41.705a1.964 1.964 0 0 1 0 3.929z"
							opacity=".2"
							transform="translate(-761.233 -383.177)"
						/>
						<path
							d="m881.086 540.826h-28.256a1.964 1.964 0 0 1 0-3.929h28.257a1.964 1.964 0 1 1 0 3.929z"
							opacity=".2"
							transform="translate(-767.611 -376.798)"
						/>
					</g>
					<path
						d="m672.663 447.825h4.09l1.946-15.778h-6.037z"
						fill="#ffd4bd"
						transform="translate(-625.18 -215.713)"
					/>
					<path
						d="m779.13 674.614h8.056a5.134 5.134 0 0 1 5.134 5.133v.167h-13.19z"
						fill="#2f2e41"
						transform="translate(-732.691 -443.837)"
					/>
					<path
						d="m631.924 447.825h4.09l1.946-15.778h-6.037z"
						fill="#ffd4bd"
						transform="translate(-605.348 -215.713)"
					/>
					<path
						d="m738.392 674.614h8.056a5.134 5.134 0 0 1 5.134 5.133v.167h-13.19z"
						fill="#2f2e41"
						transform="translate(-712.86 -443.837)"
					/>
					<path
						d="m810.548 330.219a4.646 4.646 0 1 0 -8.943 1.765l-9.4 14.873-5.852 11.793 4.829 4.286 3.716-3.911 12.154-24.309a4.643 4.643 0 0 0 3.496-4.497z"
						fill="#ffd4bd"
						transform="matrix(.98162718 .190809 -.190809 .98162718 -650.793 -413.211)"
					/>
					<path
						d="m714.2 428.329-9.214-1.428-2.2 16.67-11.959 25.735a4.648 4.648 0 1 0 6.406 1.49l12.5-22.579z"
						fill="#ffd4bd"
						transform="translate(-688.641 -323.253)"
					/>
					<path
						d="m741.6 483.794s-8.1 7.493-7.073 26.333l2.27 29.1-.572 40.571 8.323-.654 7.271-67.992 6.969 27.876-2.5 37.134 8.017.186 8.423-41.966-2-31.108-3.354-19.485z"
						fill="#2f2e41"
						transform="translate(-710.932 -350.949)"
					/>
					<path
						d="m746.921 398.3-19.358 5.42 9.843 29.492-1.471 11.282 29.848.193-6.913-21.807s3.879-7.977-.55-13.569z"
						fill="#0571a8"
						transform="translate(-707.588 -309.33)"
					/>
					<path
						d="m760.757 395.253 2.323-4.646s-.075-1.168 5.383-1.745l6.4-6.365 5.862 5.842-9.902 14.661z"
						fill="#0571a8"
						transform="matrix(.98162718 .190809 -.190809 .98162718 -633.274 -438.036)"
					/>
					<path
						d="m730.1 411.7s-6.969-7.743-10.841-3.872-1.887 16.183-1.887 16.183l12.059 1.871z"
						fill="#0571a8"
						transform="translate(-702.378 -313.443)"
					/>
					<path
						d="m741.1 358.407c11.869.053 11.867 18.1 0 18.151-11.873-.058-11.872-18.099 0-18.151z"
						fill="#ffd4bd"
						transform="translate(-709.843 -289.911)"
					/>
					<path
						d="m730.858 361.169a13.265 13.265 0 0 0 5.122 2.114c1.851.2 3.946-.576 4.689-2.284a5.267 5.267 0 0 0 .364-1.856 12.335 12.335 0 0 0 -3.122-8.839 11.186 11.186 0 0 0 -8.584-3.63c-3.067.133-5.9 1.585-8.622 3.005-2.5 1.306-5.145 2.745-6.5 5.219-2.015 3.666-.546 8.153-.155 12.318a23.206 23.206 0 0 1 -5.594 17.26l16.886 1.6a19.766 19.766 0 0 0 6.165-.063 9.854 9.854 0 0 0 4.686-16.716c-2.4-2.308-6.252-3.941-6.346-7.267"
						fill="#c6c1ac"
						transform="translate(-698.284 -284.197)"
					/>
					<path
						d="m882.556 445.664h-4.48l-2.131-17.281h6.612z"
						fill="#bc8c72"
						transform="translate(-724.136 -213.93)"
					/>
					<path
						d="m985.851 679.435h-14.451v-.183a5.623 5.623 0 0 1 5.622-5.623h8.824z"
						fill="#2f2e41"
						transform="translate(-826.289 -443.358)"
					/>
					<path
						d="m963.731 445.664h-4.48l-2.131-17.281h6.612z"
						fill="#bc8c72"
						transform="translate(-763.652 -213.93)"
					/>
					<path
						d="m1067.026 679.435h-14.446v-.183a5.623 5.623 0 0 1 5.622-5.623h8.824z"
						fill="#2f2e41"
						transform="translate(-865.804 -443.358)"
					/>
					<path
						d="m987.021 503.524s-3.761 10.207-3.223 14.5-3.761 34.382-3.223 37.068-.537 16.117 0 18.265-1.508 4.716-.433 6.864 2.582 6.566 2.582 6.566l6.984-.537 1.074-5.909a3.2 3.2 0 0 0 2.686-3.761c-.537-3.223 2.686-24.712 2.686-24.712l9.133-30.084 12.893 27.4s.537 19.34 2.149 21.489-1.612 8.058 0 10.207a35.791 35.791 0 0 1 3.223 5.909h8.058v-4.3s4.835-2.686 0-10.744l-3.267-48.393-3.223-22.026z"
						fill="#1e355f"
						transform="translate(-830.387 -359.485)"
					/>
					<circle cx="176.66" cy="66.455" fill="#bc8c72" r="10.034" />
					<path
						d="m1013.623 340.463 2.124 5.427c.87 2.224 1.867 4.6 3.908 5.844 2.519 1.532 5.883.785 8.32-.875a12.992 12.992 0 0 0 -5.848-23.647l-.1 1.816-2.229-1.868a2.167 2.167 0 0 1 -3.368.476c.586 1.214-.09 2.694-1.066 3.624-1.2 1.142-4.628 2.515-4.486 4.556.094 1.363 2.193 3.235 2.745 4.647z"
						fill="#2f2e41"
						transform="translate(-845.502 -274.7)"
					/>
					<path
						d="m1079.255 517.9a4.249 4.249 0 0 0 -1.82-6.255l.2-9.707-5.869-1.561-.091 13.712a4.272 4.272 0 0 0 7.58 3.811z"
						fill="#bc8c72"
						transform="translate(-874.975 -359.024)"
					/>
					<path
						d="m1020.76 383.99s-7.776-3.888-15.119-1.3a43.1 43.1 0 0 0 -11.231 5.616l4.752 25.054-6.48 33.694s33.262-2.592 36.286 0 3.024-3.024 3.024-3.024l-2.592-31.966 4.752-20.3z"
						fill="#505359"
						transform="translate(-836.647 -301.299)"
					/>
					<path
						d="m976.778 517.7a4.249 4.249 0 0 0 .009-6.515l2.918-9.26-5.195-3.146-3.937 13.135a4.272 4.272 0 0 0 6.2 5.785z"
						fill="#bc8c72"
						transform="translate(-825.482 -358.242)"
					/>
					<path
						d="m988.01 394.482h-3.024s-4.752 3.024-4.752 7.343-6.911 54.86-6.911 54.86l7.343 1.3 6.48-35.422 4.752-9.5z"
						fill="#505359"
						transform="translate(-827.223 -307.471)"
					/>
					<path
						d="m1057.494 401.215h8.207s2.592 2.592 3.888 8.639 2.592 29.806 2.592 29.806l-.864 21.6h-6.479l-.864-28.078-4.32-18.143z"
						fill="#505359"
						transform="translate(-868.196 -310.749)"
					/>
					<text fill="#28292c" transform="translate(92 57)">
						<tspan x="0" y="0" />
					</text>
				</svg>
			),
			title: 'Steg 2',
			text: 'En komite hos NTE ser gjennom og vurderer søknaden.',
		},
		{
			icon: (
				<svg
					height="231.996"
					viewBox="0 0 205 231.996"
					width="205"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="102.5"
						cy="102.5"
						fill="#6dbc87"
						opacity=".2"
						r="102.5"
					/>
					<path
						d="m574.973 246.545h-1.114v-30.529a17.669 17.669 0 0 0 -17.669-17.669h-64.68a17.669 17.669 0 0 0 -17.669 17.669v167.484a17.669 17.669 0 0 0 17.669 17.669h64.68a17.669 17.669 0 0 0 17.669-17.669v-115.224h1.114z"
						fill="#3f3d56"
						transform="translate(-432.748 -169.346)"
					/>
					<path
						d="m577.654 223.265v167.235a13.193 13.193 0 0 1 -13.193 13.2h-64.994a13.194 13.194 0 0 1 -13.194-13.194v-167.241a13.194 13.194 0 0 1 13.194-13.194h7.888a6.268 6.268 0 0 0 5.8 8.637h37.056a6.271 6.271 0 0 0 5.8-8.637h8.443a13.194 13.194 0 0 1 13.194 13.194z"
						fill="#f2f2f2"
						transform="translate(-440.306 -176.472)"
					/>
					<path d="m50.892 49.562h20.162v.902h-20.162z" fill="#fff" />
					<path d="m81.577 49.562h20.162v.902h-20.162z" fill="#fff" />
					<path
						d="m578.9 521.111h-65.725a1.686 1.686 0 0 1 0-3.373h65.725a1.686 1.686 0 1 1 0 3.373z"
						fill="#d1d1d1"
						transform="translate(-455.633 -363.487)"
					/>
					<path
						d="m578.9 551.028h-65.725a1.686 1.686 0 0 1 0-3.373h65.725a1.686 1.686 0 1 1 0 3.373z"
						fill="#d1d1d1"
						transform="translate(-455.633 -381.673)"
					/>
					<path
						d="m578.9 580.946h-65.725a1.686 1.686 0 0 1 0-3.373h65.725a1.686 1.686 0 1 1 0 3.373z"
						fill="#d1d1d1"
						transform="translate(-455.633 -399.858)"
					/>
					<path
						d="m112.261 49.562h20.162v.902h-20.162z"
						fill="#fff"
					/>
					<circle
						cx="91.658"
						cy="105.315"
						fill="#397f57"
						r="27.882"
					/>
					<path
						d="m584.006 386.431c-.06 0-.121 0-.182 0a3.714 3.714 0 0 1 -2.752-1.43l-4.394-5.649a3.721 3.721 0 0 1 .651-5.216l.157-.123a3.722 3.722 0 0 1 5.217.651 2.23 2.23 0 0 0 1.653.859 2.186 2.186 0 0 0 1.729-.693l8.922-9.419a3.721 3.721 0 0 1 5.254-.142l.145.137a3.721 3.721 0 0 1 .143 5.255l-13.849 14.61a3.71 3.71 0 0 1 -2.694 1.16z"
						fill="#fff"
						transform="translate(-494.783 -270.189)"
					/>
					<g fill="#3f3d56">
						<path d="m39.216 61.188h3.61v19.403h-3.61z" />
						<path d="m39.216 82.848h3.61v11.281h-3.61z" />
						<path d="m39.216 96.385h3.61v11.281h-3.61z" />
					</g>
					<circle cx="73.962" cy="36.369" fill="#fff" r="1.805" />
					<path
						d="m479.182 237.651 22.111.9 2.707-14.889-18.952-5.862z"
						fill="#ffd4bd"
						transform="translate(-348.215 -109.837)"
					/>
					<path
						d="m665.978 340 .214-7.035-10.716-2.687-19.293-5.629a4.028 4.028 0 1 0 -2.15 4.924l18.757 7.71z"
						fill="#ffd4bd"
						transform="translate(-526.654 -244.434)"
					/>
					<path
						d="m706.726 354.624 6.028-8.6.15.02a128.394 128.394 0 0 1 15.746 3.123 4.954 4.954 0 0 1 3.208 2.667 5.2 5.2 0 0 1 -.723 5.651 5.558 5.558 0 0 1 -5.712 1.9z"
						fill="#fff"
						transform="translate(-574.307 -259.113)"
					/>
					<path
						d="m478.594 481.8h-6.132l-2.917-23.652h9.05z"
						fill="#ffd4bd"
						transform="translate(-342.358 -255.933)"
					/>
					<path
						d="m595.025 475.44-6.063.916-6.42-22.95 7.949-1.353z"
						fill="#ffd4bd"
						transform="translate(-411.042 -252.23)"
					/>
					<path
						d="m715.45 435.521-21.208-3.61-.754 1.555a72.019 72.019 0 0 0 -7.057 26.75c-.512 7.841-.813 16.123-.823 24.805-.015 13.451.671 25.933 1.756 37.29l11.64-.073a274.933 274.933 0 0 1 1.371-36.123c.891-8.555 2.13-16.547 3.562-23.921a164.56 164.56 0 0 0 11.193 31.421c4.068 8.6 12.434 18.546 16.5 27.148l12.5-1.5c-3.285-8.687-9.145-21.372-12.429-30.058-4.7-12.42-6.818-20.842-11.515-33.262a38.278 38.278 0 0 0 .156-7.034c-.219-3.908-1.032-8.6-3.407-11.723z"
						fill="#2f2e41"
						transform="translate(-561.47 -311.317)"
					/>
					<path
						d="m732.476 376.7c1.059 1.98-1.456 6.081-3.276 6.474l-20.973-6.774 10.56-30.6 5.313.966 8.5 1.432 3.014 2.74a5.3 5.3 0 0 1 1.461 5.593z"
						fill="#fff"
						transform="translate(-575.22 -258.973)"
					/>
					<path
						d="m681.559 388.581-5.933-3.786-7.8 7.829-14.715 13.69a4.028 4.028 0 1 0 3.128 4.368l16.228-12.163z"
						fill="#ffd4bd"
						transform="translate(-538.741 -282.678)"
					/>
					<path
						d="m717.46 373.8-4.3-9.581.094-.119a128.46 128.46 0 0 1 10.746-11.924 4.954 4.954 0 0 1 3.934-1.39 5.2 5.2 0 0 1 4.484 3.514 5.557 5.557 0 0 1 -1.295 5.878z"
						fill="#fff"
						transform="translate(-578.219 -261.993)"
					/>
					<circle cx="152.61" cy="75.451" fill="#ffd4bd" r="9.328" />
					<path
						d="m747.164 312.369.484 5.187-13.555-3.39a3.1 3.1 0 0 1 -2.111-1.821c-.376-.907-.818-7.135-.313-7.977l.448-.745a5.083 5.083 0 0 0 .208-4.854 5.351 5.351 0 0 0 -3.076-2.628l-.045-.022-.285-.177a2.221 2.221 0 0 1 -1.176-1.359 2.291 2.291 0 0 1 .224-1.848 11.215 11.215 0 0 1 5.123-4.517 11.566 11.566 0 0 1 8.5-.255 1.51 1.51 0 0 1 1.388-.353c7.02 1.7 7.6 6.412 6.211 13.976l2.161 11.817a3.317 3.317 0 0 1 -2.959 4.106z"
						fill="#c6c1ac"
						transform="translate(-587.021 -223.402)"
					/>
					<path
						d="m693.779 696.982h-3.5l-.625-3.3-1.6 3.3h-9.282a2.087 2.087 0 0 1 -1.186-3.8l7.414-5.123v-3.34l7.8.465z"
						fill="#2f2e41"
						transform="translate(-556.048 -464.986)"
					/>
					<path
						d="m814.21 696.982h-3.5l-.625-3.3-1.6 3.3h-9.285a2.087 2.087 0 0 1 -1.186-3.8l7.412-5.119v-3.34l7.8.465z"
						fill="#2f2e41"
						transform="translate(-629.251 -464.986)"
					/>
				</svg>
			),
			title: 'Steg 3',
			text: 'Du får forhåpentligvis innvilget en sum til deres formål.',
		},
		{
			icon: (
				<svg
					height="225.996"
					viewBox="0 0 205 225.996"
					width="205"
					xmlns="http://www.w3.org/2000/svg">
					<circle
						cx="102.5"
						cy="102.5"
						fill="#6dbc87"
						opacity=".2"
						r="102.5"
					/>
					<path
						d="m370.757 342.337c-1.524-1.974-3.677-3.8-3.814-6.238-.125-2.235 1.541-4.453.839-6.591-.54-1.644-2.323-2.636-4.065-3.161a15.8 15.8 0 0 0 -10.153.391l.176-.124a6.147 6.147 0 0 0 -5.861 1.373 12.382 12.382 0 0 0 -3.207 5.1 39.93 39.93 0 0 1 -2.277 5.579 8.186 8.186 0 0 1 -4.55 3.966 2.857 2.857 0 0 0 4.029.882 5.1 5.1 0 0 0 .848-.876l2.48-3.016c.851 1.513-.42 3.6.625 5a3.973 3.973 0 0 0 2.438 1.193 44.108 44.108 0 0 0 21.578-.8 2.106 2.106 0 0 0 1.42-.905 1.828 1.828 0 0 0 -.506-1.773z"
						fill="#2f2e41"
						transform="translate(-334.893 -252.432)"
					/>
					<path
						d="m448.562 391.618a3.827 3.827 0 0 0 -.087.6l-15.623 9.013-3.8-2.186-4.048 5.3 6.347 4.523a2.91 2.91 0 0 0 3.491-.085l16.11-12.71a3.818 3.818 0 1 0 -2.392-4.452z"
						fill="#bc8c72"
						transform="translate(-392.43 -294.007)"
					/>
					<path
						d="m417.422 412.513-5.049 6.544a1.637 1.637 0 0 1 -2.475.136l-5.716-5.936a4.545 4.545 0 1 1 5.574-7.181l7.17 4.009a1.637 1.637 0 0 1 .5 2.428z"
						fill="#b5e4fc"
						transform="translate(-377.523 -304.866)"
					/>
					<path
						d="m245.038 608.183h4.462l2.121-17.2h-6.581z"
						fill="#bc8c72"
						transform="translate(-212.088 -386.509)"
					/>
					<path
						d="m422.76 758.1h8.781a5.6 5.6 0 0 1 5.6 5.6v.182h-14.38z"
						fill="#2f2e41"
						transform="translate(-390.948 -537.878)"
					/>
					<path
						d="m155.148 608.183h4.459l2.121-17.2h-6.581z"
						fill="#bc8c72"
						transform="translate(-152.748 -386.509)"
					/>
					<path
						d="m332.87 758.1h8.781a5.6 5.6 0 0 1 5.6 5.6v.182h-14.381z"
						fill="#2f2e41"
						transform="translate(-331.608 -537.878)"
					/>
					<circle
						cx="7.706"
						cy="7.706"
						fill="#bc8c72"
						r="7.706"
						transform="matrix(.10912711 -.9940278 .9940278 .10912711 14.112 93.452)"
					/>
					<path
						d="m356.277 399.03 4.54 2.249s5.072 8.815 2.163 17.18c-1.413 4.061 2.565 17.007 2.565 17.007s6.163 58.641 6.183 76.74c-7.657 1.177-14.931 2.632-15.295-1s-4.345-46.639-4.345-46.639-5.475 47.73-9.839 48.458-13.093-.364-13.093-.364 6.93-69.916 11.658-74.28l.727-3.637-.019-32.646 5.447-2.6z"
						fill="#2f2e41"
						transform="translate(-329.156 -300.845)"
					/>
					<path
						d="m349.868 464.456a3.907 3.907 0 0 0 1.2-5.87l5.28-35.192-6.9-.331-4.116 35.046a3.928 3.928 0 0 0 4.537 6.347z"
						fill="#bc8c72"
						transform="translate(-338.987 -316.71)"
					/>
					<path
						d="m360.786 421.233-8.249-.521a1.637 1.637 0 0 1 -1.5-1.975l1.719-8.06a4.545 4.545 0 1 1 9.071.6l.691 8.185a1.637 1.637 0 0 1 -1.734 1.771z"
						fill="#b5e4fc"
						transform="translate(-343.578 -305.732)"
					/>
					<path
						d="m386.385 340.146a5.878 5.878 0 0 0 -2.4-3.317 9.826 9.826 0 0 0 -12.561.764 6.419 6.419 0 0 0 -1.94 3.109 3.814 3.814 0 0 0 .813 3.473l.154.1a1.388 1.388 0 0 1 .87-1.445 6.949 6.949 0 0 1 1.774-.4 6.523 6.523 0 0 0 4.421-3.12 7.366 7.366 0 0 0 4.222 4.4c1.018.4 3.816.946 4.785.44 1.238-.65.162-2.642-.138-4.004z"
						fill="#2f2e41"
						transform="translate(-355.696 -258.522)"
					/>
					<path
						d="m735.88 508.32c-5-6.691-14.151-8.306-18.7-4.908s-5.592 12.631-.592 19.322c4.628 6.193 13.013 7.882 17.631 5.562a.578.578 0 0 0 0 .22l.26 1.287a.587.587 0 0 0 .937.346l2.2-1.722a.587.587 0 0 0 -.109-.993l-1.186-.564a.578.578 0 0 0 -.13-.041c3.564-3.763 4.327-12.302-.311-18.509z"
						fill="#f7c2b5"
						transform="translate(-612.966 -379.613)"
					/>
					<path
						d="m746.98 512.107a9.663 9.663 0 0 1 8.093 10.565c-.054.564.828.56.881 0a10.565 10.565 0 0 0 -8.74-11.414c-.554-.095-.792.754-.234.849z"
						fill="#fff"
						transform="translate(-635.002 -385.925)"
					/>
					<path
						d="m759.259 141.219c4.371-7.118 2.492-16.218-2.345-19.188s-13.8-.531-18.175 6.586c-4.046 6.588-2.519 15 1.343 18.439a.58.58 0 0 0 -.205.079l-1.1.717a.587.587 0 0 0 .024 1l2.415 1.413a.587.587 0 0 0 .883-.468l.086-1.311a.584.584 0 0 0 -.01-.136c4.812 1.916 13.025-.529 17.084-7.131z"
						fill="#7dc189"
						transform="translate(-606.642 -117.169)"
					/>
					<path
						d="m784.974 147.343a9.663 9.663 0 0 1 -6.829 11.423c-.544.158-.215.976.326.819a10.566 10.566 0 0 0 7.379-12.337c-.117-.548-.994-.459-.876.095z"
						fill="#fff"
						transform="translate(-634.048 -134.39)"
					/>
					<path
						d="m529.065 251.925c-.366-8.345-7.019-14.831-12.69-14.582s-11.732 7.292-11.367 15.636c.338 7.724 6.317 13.84 11.44 14.522a.58.58 0 0 0 -.126.18l-.509 1.21a.587.587 0 0 0 .58.814l2.792-.182a.587.587 0 0 0 .469-.882l-.663-1.134a.578.578 0 0 0 -.085-.107c5.06-1.107 10.494-7.735 10.159-15.475z"
						fill="#0089c4"
						transform="matrix(.9612617 .27563736 -.27563736 .9612617 -360.323 -316.11)"
					/>
					<path
						d="m553.167 254.641a9.663 9.663 0 0 1 .741 13.287c-.362.436.369.929.728.5a10.566 10.566 0 0 0 -.8-14.354c-.402-.393-1.077.174-.669.567z"
						fill="#fff"
						transform="matrix(.9612617 .27563736 -.27563736 .9612617 -387.792 -335.39)"
					/>
					<path
						d="m867.852 262.112a9.876 9.876 0 0 1 7.828-.447c2.454 1.043 4.127 3.715 3.334 6.08-.36 1.072-1.179 2.127-.86 3.21a3.3 3.3 0 0 0 1.945 1.775 7.246 7.246 0 0 1 2.372 1.38 1.551 1.551 0 0 1 -.025 2.308c-.251.169-.564.249-.807.426a1.282 1.282 0 0 0 -.4 1.435 3.022 3.022 0 0 0 .969 1.275c.863.745 2 1.6 1.736 2.654a1.862 1.862 0 0 1 -1.256 1.175 6.242 6.242 0 0 1 -1.844.243l-25.478.8a9.658 9.658 0 0 1 -2.516-.141 2.978 2.978 0 0 1 -1.976-1.327 2.767 2.767 0 0 1 .435-2.859 27.112 27.112 0 0 1 2.16-2.283 3.623 3.623 0 0 0 1.157-2.742 7.006 7.006 0 0 0 -1.007-2.222 2.212 2.212 0 0 1 -.108-2.307c.692-.944 2.354-.858 3.141-1.738a3.9 3.9 0 0 0 .538-2.475 6 6 0 0 1 3.6-4.905 7.08 7.08 0 0 1 6.442.439z"
						fill="#6c290c"
						transform="translate(-702.84 -209.633)"
					/>
					<path
						d="m780.3 443.476v4.167l-16.071 1.982v-6.15z"
						fill="#ffd4bd"
						transform="translate(-584.256 -289.132)"
					/>
					<path
						d="m993.977 561.142v13.435h-.17a5.23 5.23 0 0 1 -5.229-5.229v-8.206z"
						fill="#2f2e41"
						transform="translate(-793.897 -407.86)"
					/>
					<path
						d="m719.862 456.668-1.023 4.039-16.066-2.024 1.51-5.961z"
						fill="#ffd4bd"
						transform="translate(-543.689 -295.237)"
					/>
					<path
						d="m932.018 582.435-3.3 13.024-.165-.042a5.23 5.23 0 0 1 -3.786-6.353l2.015-7.955z"
						fill="#2f2e41"
						transform="translate(-751.669 -421.042)"
					/>
					<path
						d="m882.318 447.637.868.9s5.663 4.44 1.473 16.137l-6.853 19.743 22.971.9-.492 9.021s-33.312 1.853-33.021-3.637 1.521-25.818 1.521-25.818l-6.921-5.558 1.669-9.4z"
						fill="#1e355f"
						transform="translate(-710.249 -332.932)"
					/>
					<path
						d="m846.609 474-2.661 2.728-8.922 24.526s-1.359 6.117 1.7 7.477 22.74 8.964 22.74 8.964l2.01-7.233-15.165-9.1 7.068-9.282 1.064-14.629z"
						fill="#1e355f"
						transform="translate(-692.333 -350.335)"
					/>
					<path
						d="m861.577 337.9 22.647-4.528.492 12.425-5.866 12.5 1.247 14.811-20.3 3.546.636-19.475s-5.451-7.689-2.24-15.44z"
						fill="#d1d1d1"
						transform="translate(-707.159 -257.499)"
					/>
					<path
						d="m960.045 254.307a3.651 3.651 0 0 1 -3.43 4.425l-21.715 25.183-5.049-6.128 22.935-22.827a3.671 3.671 0 0 1 7.261-.653z"
						fill="#ffd4bd"
						transform="translate(-755.129 -203.385)"
					/>
					<path
						d="m930.051 325.657a1.635 1.635 0 0 1 -.541 1.251l-6.107 5.5a4.546 4.546 0 1 1 -7.027-5.771l4.262-7.055a1.637 1.637 0 0 1 2.444-.415l6.374 5.266a1.635 1.635 0 0 1 .594 1.227z"
						fill="#d1d1d1"
						transform="translate(-745.552 -247.874)"
					/>
					<path
						d="m765.611 384.572a3.651 3.651 0 0 1 4.055-3.86l25.25-21.639 4.073 6.816-26.1 19.127a3.671 3.671 0 0 1 -7.277-.444z"
						fill="#ffd4bd"
						transform="translate(-646.704 -274.467)"
					/>
					<path
						d="m836.856 351.691a1.635 1.635 0 0 1 .723-1.156l6.862-4.519a4.546 4.546 0 0 1 6.081 6.76l-5.273 6.336a1.637 1.637 0 0 1 -2.478.044l-5.512-6.163a1.635 1.635 0 0 1 -.403-1.302z"
						fill="#d1d1d1"
						transform="translate(-693.731 -265.079)"
					/>
					<circle cx="164.069" cy="66.252" fill="#ffd4bd" r="8.347" />
					<path
						d="m888.073 281.264a5.175 5.175 0 0 1 -4.551-5.475 4.421 4.421 0 0 1 -3.787 4.5c-1.209.133-2.535-.233-3.587.377-1.166.676-1.539 2.316-2.753 2.9a2.607 2.607 0 0 1 -3.243-1.371 5.2 5.2 0 0 1 -.053-3.81 10.757 10.757 0 0 1 17.907-4.41 6.888 6.888 0 0 1 2.131 4.1 4.04 4.04 0 0 1 -1.99 3.968z"
						fill="#6c290c"
						transform="translate(-715.464 -216.198)"
					/>
				</svg>
			),
			title: 'Steg 4',
			text: 'Fortsett med det du brenner for, bare litt bedre.',
		},
	],
}) {
	if (!steps?.length > 0) return null;

	return (
		<MaxWidth>
			<Steps aria-label={title || ''}>
				{steps.map((step, i) => {
					if (i === 0 && !showFirstStep) return null;
					return (
						<Step key={i}>
							<Inner>
								{step?.title && (
									<Heading level="h3">{step.title}</Heading>
								)}
								{step?.icon && (
									<Icon
										aria-label={
											step.title
												? step.title
												: 'Illustrasjon av steg ' + i
										}>
										{step.icon}
									</Icon>
								)}

								{step?.text && <Text>{step.text}</Text>}
							</Inner>
						</Step>
					);
				})}
			</Steps>
		</MaxWidth>
	);
}
